exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-games-8-colors-delegation-jsx": () => import("./../../../src/pages/games/8-colors-delegation.jsx" /* webpackChunkName: "component---src-pages-games-8-colors-delegation-jsx" */),
  "component---src-pages-games-black-gold-jsx": () => import("./../../../src/pages/games/black-gold.jsx" /* webpackChunkName: "component---src-pages-games-black-gold-jsx" */),
  "component---src-pages-games-bridge-jsx": () => import("./../../../src/pages/games/bridge.jsx" /* webpackChunkName: "component---src-pages-games-bridge-jsx" */),
  "component---src-pages-games-challenge-accepted-jsx": () => import("./../../../src/pages/games/challenge-accepted.jsx" /* webpackChunkName: "component---src-pages-games-challenge-accepted-jsx" */),
  "component---src-pages-games-emotional-intelligence-jsx": () => import("./../../../src/pages/games/emotional-intelligence.jsx" /* webpackChunkName: "component---src-pages-games-emotional-intelligence-jsx" */),
  "component---src-pages-games-future-city-jsx": () => import("./../../../src/pages/games/future-city.jsx" /* webpackChunkName: "component---src-pages-games-future-city-jsx" */),
  "component---src-pages-games-history-by-cards-jsx": () => import("./../../../src/pages/games/history-by-cards.jsx" /* webpackChunkName: "component---src-pages-games-history-by-cards-jsx" */),
  "component---src-pages-games-mansion-matilda-secrets-jsx": () => import("./../../../src/pages/games/mansion-matilda-secrets.jsx" /* webpackChunkName: "component---src-pages-games-mansion-matilda-secrets-jsx" */),
  "component---src-pages-games-middleages-secrets-jsx": () => import("./../../../src/pages/games/middleages-secrets.jsx" /* webpackChunkName: "component---src-pages-games-middleages-secrets-jsx" */),
  "component---src-pages-games-place-under-sun-jsx": () => import("./../../../src/pages/games/place-under-sun.jsx" /* webpackChunkName: "component---src-pages-games-place-under-sun-jsx" */),
  "component---src-pages-games-technology-car-jsx": () => import("./../../../src/pages/games/technology-car.jsx" /* webpackChunkName: "component---src-pages-games-technology-car-jsx" */),
  "component---src-pages-games-timelab-jsx": () => import("./../../../src/pages/games/timelab.jsx" /* webpackChunkName: "component---src-pages-games-timelab-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

